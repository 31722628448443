import { IObjectDifference } from '../models/custom/object-difference.model';
import { RoleName } from '../models/custom/role-name.enum';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { IUserDTO } from '../models/generated/user-dto.model';
import { IRolesDTO } from '../models/generated/roles-dto.model';
import { SystemUserStatus } from '../models/custom/system-user-status.enum';
import { SystemUserType } from '../models/custom/system-user-type.enum';
import { ValidatorFn, AbstractControl, FormArray } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DataShareService } from '../services/data-share.service';
import { KeycloakOptions, KeycloakConfig } from 'keycloak-angular';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd';
import { TranslateService } from '@ngx-translate/core';
import * as fastDeepEqual from 'fast-deep-equal';

export abstract class Utils {
  /**
   * @param object object to access the value from
   * @param property property name, supports nested properties, separated with a `.` (period)
   * @returns value of the object's property
   * @example
   * Utils.getPropertyValue(this.department, 'head.name');
   */
  public static getPropertyValue = (object: any, property: string): any => object && property && (property.includes('.') ? property.split('.').reduce((acc, cur) => acc && acc[cur], object) : object[property]);

  /**
   * @param array Array with elements to search if `objectOrValue` is part of
   * @param objectOrValue object or value to check its existence in `array`
   * @param property optional property, in case `objectOrValue` is of type "object", then specify the "comparator" property (usually unique),
   *                 supporting nested properties, separated with a `.` (period),
   *                 if omitted, compare with objects or direct values will be used, instead
   * @returns boolean value indicating the existence of `objectOrValue` in specified `array` by optional `property` comparator
   * @example
   * Utils.getIncludesByProperty(this.users, this.currentUser, 'guid');
   * Utils.getIncludesByProperty(this.users, this.currentUser, 'subject.sid'); // Returns `true` if at least one user exists, based on its `subject.sid` value
   */
  public static getIncludesByProperty = (array: any[] | Array<any>, objectOrValue: any, property?: string): boolean => array && array.length && objectOrValue && (property && !!array.find((item: any) => Utils.getPropertyValue(item, property) === Utils.getPropertyValue(objectOrValue, property)) || array.includes(objectOrValue)) || false;

  /**
   * @example <li *ngFor="let item of items; index as i; tracyBy: utilsClassReferenceFromController.getTrackByPropertyFn('sid')"></li>
   * @returns trackBy Fn
   */
  public static getTrackByPropertyFn = (property: string) => (index: number, item: any): any | number => Utils.getPropertyValue(item, property) || index;

  /**
   * @example <li *ngFor="let item of items; index as i; tracyBy: utilsClassReferenceFromController.trackByIndexFn"></li>
   * @returns trackBy index Fn
   */
  public static trackByIndexFn = (index: number): number => index;

  /**
   * @example <select [compareWith]="utilsClassReferenceFromController.getCompareWithFn('sid')"></select>
   * @returns compareWith Fn
   */
  public static getCompareWithFn = (property?: string) => (a: any, b: any): boolean => a && b && (property && Utils.getPropertyValue(a, property) === Utils.getPropertyValue(b, property) || JSON.stringify(a) === JSON.stringify(b));

  /**
   * @example
   * this.userChangeDifferences = Utils.getObjectDifferences(this.existingEntity, this.modifiedEntity, [
   *   'subject',
   *   'emailAddress',
   *   'firstName',
   *   'lastName',
   *   'isActive',
   *   'isBlocked',
   *   'roles'
   * ]);
   * @returns IObjectDifference[]
   */
  public static getObjectDifferences = (oldObject: any, newObject: any, propertiesWhitelist: string[] = []): IObjectDifference[] => oldObject && newObject && Object.keys(oldObject).filter((key: string) => JSON.stringify(oldObject[key]) !== JSON.stringify(newObject[key])).map((key: string) => ({ key, existingValue: oldObject[key], modifiedValue: newObject[key] })).filter((objectDifference: IObjectDifference) => !propertiesWhitelist.length || propertiesWhitelist.includes(objectDifference.key)) || [];

  /**
   * @example <td>{{ utilsClassReferenceFromController.getListByPropertySeparatedBySeperator(row.roles, 'roleName') }}</td>
   * @returns string with array items, separated by a separator (default `', '`) based on the specified property
   */
  public static getListByPropertySeparatedBySeperator = (array: Array<any> | any[], property: string, separator: string = ', '): string => array && array.length && array.map((item: any) => item[property]).join(separator) || '';

  /**
   * Checks whether the current user has the required role/s, specified by `currentUserRoles`, `requiredRoles` and the optional `atLeastOne` parameter (default `true`)
   * @example this.currentUserProfile.isSysAdmin = Utils.hasRole(this.currentUserProfile.roles, environment.sysAdminRoleNames);
   * @returns boolean
   */
  public static hasRole = (currentUserRoles: RoleName[], requiredRoles: RoleName[], atLeastOne: boolean = true): boolean => currentUserRoles && requiredRoles &&  (atLeastOne && requiredRoles.some((requiredRole: RoleName) => currentUserRoles.includes(requiredRole)) || requiredRoles.every((requiredRole: RoleName) => currentUserRoles.includes(requiredRole)));

  public static getFormattedDate = (requiredDate: Date, dateFormat: string = environment.dateFormat): string => moment(requiredDate).format(dateFormat);

  public static getDateFromString = (requiredDate: string, dateFormat: string = environment.dateFormat): Date => moment(requiredDate, dateFormat).toDate();

  public static getDateFromDateTime = (date: Date, toJSON?: boolean): Date | string => toJSON && (Utils.getDateFromDateTime(date) as Date).toJSON() ||  new Date(date.toDateString());

  public static getEndOfDayFromDateTime = (date: Date, toJSON?: boolean): Date | string => {
    const newDate: Date = new Date(date);
    newDate.setHours(23, 59, 59, 999);
    return toJSON && newDate.toJSON() || newDate;
  }

  public static getCurrentDate = (dateFormat?: string): string | Date => dateFormat && moment().format(dateFormat) || Utils.getDateFromDateTime(moment().toDate());

  public static getFirstDayOfCurrentMonth = (dateFormat?: string): string | Date => dateFormat && moment().startOf('month').format(dateFormat) || Utils.getDateFromDateTime(moment().startOf('month').toDate());

  public static getLastDayOfCurrentMonth = (dateFormat?: string): string | Date => dateFormat && moment().endOf('month').format(dateFormat) || Utils.getDateFromDateTime(moment().endOf('month').toDate());

  public static getLastDayOfMonthFromDate = (date: Date, dateFormat?: string): string | Date => dateFormat && moment(date).endOf('month').format(dateFormat) || Utils.getDateFromDateTime(moment(date).endOf('month').toDate());

  public static getLastDayOfPreviousMonthFromDate = (date: Date, dateFormat?: string): string | Date => dateFormat && moment(date).subtract(1, 'months').endOf('month').format(dateFormat) || Utils.getDateFromDateTime(moment(date).subtract(1, 'months').endOf('month').toDate());

  public static canApproveOrDisapproveUser = (userToApproveOrReject: IUserDTO, allowedRolesToApproveOrReject: IRolesDTO[], dataShareService: DataShareService): boolean => {
    if (userToApproveOrReject && userToApproveOrReject.userType && (userToApproveOrReject.userType.shortCode === SystemUserType.INDIVID_WEB_USER || userToApproveOrReject.userType.shortCode === SystemUserType.ORGANIZATION_WEB_USER)) {
      return Utils.canApproveOrDisapproveWebUser(userToApproveOrReject, dataShareService);
    }
    return userToApproveOrReject
      && dataShareService.getPrivilegesOfActiveComponentUrlRelativeToActiveFeature() && dataShareService.getPrivilegesOfActiveComponentUrlRelativeToActiveFeature().canUpdate
      && userToApproveOrReject.status && userToApproveOrReject.status.shortCode === SystemUserStatus.FOR_APPROVAL
      && (dataShareService.currentUserProfile.isSysAdmin || (userToApproveOrReject.modifiedBy && userToApproveOrReject.modifiedBy.toLowerCase()) !== (dataShareService.currentUserProfile.email && dataShareService.currentUserProfile.email.toLowerCase()))
      && (allowedRolesToApproveOrReject && userToApproveOrReject.roles && Utils.hasRole(userToApproveOrReject.roles.map((role: IRolesDTO) => role.roleName) as RoleName[], allowedRolesToApproveOrReject.map((role: IRolesDTO) => role.roleName) as RoleName[]));
  }

  public static canModifyUser = (userToModify: IUserDTO, allowedRolesToModify: IRolesDTO[], dataShareService: DataShareService): boolean => {
    return userToModify
      && dataShareService.getPrivilegesOfActiveComponentUrlRelativeToActiveFeature() && dataShareService.getPrivilegesOfActiveComponentUrlRelativeToActiveFeature().canUpdate
      && userToModify.status && userToModify.status.shortCode !== SystemUserStatus.FOR_APPROVAL
      && (allowedRolesToModify && (!userToModify.roles || !userToModify.roles.length || Utils.hasRole(userToModify.roles.map((role: IRolesDTO) => role.roleName) as RoleName[], allowedRolesToModify.map((role: IRolesDTO) => role.roleName) as RoleName[])));
  }

  public static canApproveOrDisapproveWebUser = (webUserToApproveOrReject: IUserDTO, dataShareService: DataShareService): boolean => {
    return webUserToApproveOrReject
      && dataShareService.getPrivilegesOfActiveComponentUrlRelativeToActiveFeature() && dataShareService.getPrivilegesOfActiveComponentUrlRelativeToActiveFeature().canUpdate
      && webUserToApproveOrReject.status && webUserToApproveOrReject.status.shortCode === SystemUserStatus.FOR_APPROVAL
      && Utils.hasRole(dataShareService.currentUserProfile.roles, [RoleName.SYSADMIN]);
  }

  public static canModifyWebUser = (webUserToModify: IUserDTO, dataShareService: DataShareService): boolean => {
    return webUserToModify
      && dataShareService.getPrivilegesOfActiveComponentUrlRelativeToActiveFeature() && dataShareService.getPrivilegesOfActiveComponentUrlRelativeToActiveFeature().canUpdate
      && webUserToModify.status && webUserToModify.status.shortCode !== SystemUserStatus.FOR_APPROVAL
      && Utils.hasRole(dataShareService.currentUserProfile.roles, [RoleName.SYSADMIN]);
  }

  public static canResendEmailToUser = (user: IUserDTO | undefined | null, dataShareService: DataShareService): boolean => !!user && user.status && (user.status.shortCode === SystemUserStatus.APPROVED || user.status.shortCode === SystemUserStatus.REFUSED) && Utils.hasRole(dataShareService.currentUserProfile.roles, [ RoleName.SYSADMIN ]);

  public static getResendEmailToUserRequest = (user: IUserDTO, userService: UserService, nzNotificationService: NzNotificationService, translateService: TranslateService, bypassErrorInterceptor: boolean | undefined = false): Observable<boolean> => {
    return userService.requestPasswordReset(user.emailAddress, bypassErrorInterceptor).pipe(
      map(
        (response: string) => {
          if (response === 'emailNotSend' || response === 'passwordResetNotAllowed' || response === 'userIsBlocked') {
            nzNotificationService.error(translateService.instant('error'), translateService.instant(response));
            return false;
          } else {
            nzNotificationService.success(translateService.instant('success'), translateService.instant('emailSentSuccessfully'));
            return true;
          }
        }
      )
    );
  }

  /**
   * @example new FormArray([], Utils.arrayValidatorMaxLength(10));
   */
  public static arrayValidatorMaxLength = (max: number): ValidatorFn | null | never => (control: AbstractControl | FormArray): { [key: string]: boolean } | null | never => control instanceof FormArray && (control.controls.length > max && { maxLength: true } || null);

  /**
   * @example new FormArray([], Utils.arrayValidatorMinLength(1));
   */
  public static arrayValidatorMinLength = (min: number): ValidatorFn | null | never => (control: AbstractControl | FormArray): { [key: string]: boolean } | null | never => control instanceof FormArray && (control.controls.length < min && { minLength: true } || null);

  public static getFileNameAndUrlFromBlobHttpResponse(response: HttpResponse<Blob>, domSanitizer?: DomSanitizer): { fileName: string, url: SafeResourceUrl | string } | null {
    if (response) {
      let contentDisposition: string = response.headers.get('content-disposition');
      contentDisposition = contentDisposition.slice((contentDisposition.indexOf('filename=') + 'filename='.length));
      return {
        fileName: contentDisposition.slice(0, contentDisposition.indexOf(';')),
        url: domSanitizer && domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(response.body)) || URL.createObjectURL(response.body)
      };
    }
    return null;
  }

  public static downloadFileFromBlobHttpResponse(response: HttpResponse<Blob>): void {
    if (response) {
      const { fileName, url } = Utils.getFileNameAndUrlFromBlobHttpResponse(response);
      const anchorElement: HTMLAnchorElement = document.createElement('a');
      anchorElement.href = url as string;
      anchorElement.download = fileName;
      anchorElement.target = '_blank';
      anchorElement.hidden = true;
      document.body.appendChild(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
    }
  }

  public static getKeycloakConfigurations = (roleName?: RoleName): KeycloakOptions => roleName && environment.roleBasedKeycloakConfigurationsOverrides[roleName] && { ...environment.keycloak, ...{ config: { ...(environment.keycloak.config as KeycloakConfig), ...environment.roleBasedKeycloakConfigurationsOverrides[roleName] } } } || environment.keycloak;

  public static clearLastLoggedInRoleNameFromLocalStorage = (): void => localStorage.removeItem(environment.loggedInUserRoleNameLocalStorageKey);

  /**
   * Mostly used in edit-mode forms, to disable the Save/Submit button if the form is changed and then immediately reverted back to the original values by hand/manually, so this comparision is needed.
   * @param existingObjectValue The current, untouched object values
   * @param modifiedObjectValue The modified object values, i.e.: After modifying the form (edit) for an entity
   * @param trimEachPropertyValueBeforeCompare Defaults to `true`. Specifies whether to ignore leading and trailing whitespaces on all object properties' values
   * @example
   * <button [disabled]="request?.$formGroup?.pristine || request?.$formGroup?.invalid || !utilsClassRef.deepCompare(existingEntity, request?.$formGroup?.value)">Save</button>
   */
  public static deepCompare = (existingObjectValue: unknown | undefined | null, modifiedObjectValue: unknown | undefined | null, trimEachPropertyValueBeforeCompare: boolean = true): boolean => existingObjectValue && modifiedObjectValue && fastDeepEqual(trimEachPropertyValueBeforeCompare && JSON.parse(JSON.stringify(existingObjectValue, ((key: string, value: any) => typeof value === 'string' && value.trim() || value))) || existingObjectValue, trimEachPropertyValueBeforeCompare && JSON.parse(JSON.stringify(modifiedObjectValue, trimEachPropertyValueBeforeCompare && ((key: string, value: any) => typeof value === 'string' && value.trim() || value))) || modifiedObjectValue);
}
