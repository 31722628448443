import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'boa-textarea-dialog',
  templateUrl: './textarea-dialog.component.html',
  styleUrls: ['./textarea-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextareaDialogComponent implements OnInit {
  textareaFormGroup: FormGroup;
  @Input()
  placeholder: string;
  @Input()
  initialValue = '';
  @Input()
  required = true;
  @Input()
  submitButtonIconName: string;
  @Input()
  submitButtonIconTheme: 'fill' | 'outline' | 'twotone' = 'outline';
  @Input()
  submitButtonLabel: string;
  @Input()
  isSaving = false;
  @Output()
  save: EventEmitter<string> = new EventEmitter<string>();

  constructor(private nzModalRef: NzModalRef) {}

  ngOnInit(): void {
    this.setDialogConfigurations();
    this.buildForm();
  }

  private setDialogConfigurations(): void {
    this.nzModalRef.getInstance().nzMaskClosable = false;
    this.nzModalRef.getInstance().nzKeyboard = false;
    this.nzModalRef.getInstance().nzFooter = null;
    this.nzModalRef.getInstance().nzWidth = '384px';
    this.nzModalRef.getInstance().nzNoAnimation = true;
  }

  private buildForm(): void {
    const validators: ValidatorFn[] = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    this.textareaFormGroup = new FormGroup({
      textarea: new FormControl(this.initialValue, validators)
    });
  }
}
