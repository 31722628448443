import { NgModule, APP_INITIALIZER,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NZ_I18N, en_US } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { Utils } from './core/utils/utils';
import { RoleName } from './core/models/custom/role-name.enum';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {NgxPaginationModule} from 'ngx-pagination'

export function keycloakInitializer(keycloakService: KeycloakService): () => Promise<any> {
  return (): Promise<any> => keycloakService.init(Utils.getKeycloakConfigurations(localStorage.getItem(environment.loggedInUserRoleNameLocalStorageKey) as RoleName));
}

registerLocaleData(en);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    CoreModule,
    SharedModule,
    LoadingBarHttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    KeycloakAngularModule,
    NgMultiSelectDropDownModule
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: {
        ...en_US,
        Pagination: {
          ...en_US.Pagination,
          items_per_page: ''
        },
        DatePicker: {
          ...en_US.DatePicker,
          lang: {
            ...en_US.DatePicker.lang,
            placeholder: environment.nzDateFormat
          }
        }
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakInitializer,
      multi: true,
      deps: [KeycloakService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
