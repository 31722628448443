import { NgModule, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { Routes, RouterModule, ROUTES } from '@angular/router';
import { KeycloakGuard } from './core/guards/keycloak.guard';
import { environment } from '../environments/environment';

export const appRoutes: Routes = [
  //{ path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: '', redirectTo:'/panel/staff/dashboard',pathMatch:'full'},
  { path: `role/:roleName/${environment.panelBaseUrlRelativeToRoot}`, canActivate: !environment.mock && [KeycloakGuard], children: [] },
  { path: environment.panelBaseUrlRelativeToRoot, canActivate: !environment.mock && [KeycloakGuard], loadChildren: () => import('./panel/panel.module').then(m => m.PanelModule) }
];

@NgModule({
  imports: [RouterModule.forRoot([], { useHash: true })],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES, // AoT Support DO NOT EVEN THINK ABOUT REMOVING THIS
      multi: true,
      useFactory: () => appRoutes,
      useValue: {}
    },
    {
      provide: ANALYZE_FOR_ENTRY_COMPONENTS, // AoT Support DO NOT EVEN THINK ABOUT REMOVING THIS
      useValue: appRoutes,
      multi: true
    }
  ]
})
export class AppRoutingModule { }
