import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpClientUtils } from '../utils/http-client-utils';
import { environment } from '../../../environments/environment';
import { ITableLazyLoadEvent } from '../models/custom/table-lazy-load-event.model';
import { map } from 'rxjs/operators';
import { isArray } from 'util';
import { skipErrorInterceptorHeader } from '../interceptors/http-error.interceptor';

export abstract class CrudService<TListEntity, TFormEntity> {
  protected baseUrlRelativeToApi!: string;
  protected getOneEntityUrlRelativeToBase!: string;
  protected getOneEntityByFilterCriteriaUrlRelativeToBase!: string;
  protected getAllEntitiesUrlRelativeToBase = 'Get';
  protected createEntityUrlRelativeToBase = 'Create';
  protected updateEntityUrlRelativeToBase = 'Update';
  protected deleteEntityUrlRelativeToBase = 'Delete';
  protected closeEntityUrlRelativeToBase = 'Close';
  protected deleteEntitiesUrlRelativeToBase = 'DeleteMany';
  protected exportAllEntitiesUrlRelativeToBase = 'Excel';

  protected getPostAllEntitiesUrlRelativeToBase = 'GetViban';
  protected verifyVibanEntitiesUrlRelativeToBase = 'CheckVibanExist';

  constructor(protected httpClient: HttpClient) { }

  public getAll = (tableLazyLoadEvent: ITableLazyLoadEvent,request: any, bypassErrorInterceptor?: boolean): Observable<HttpResponse<TListEntity[]>> => this.httpClient.post<boolean | TListEntity | TFormEntity | any>((`${environment.apiUrl}/${this.baseUrlRelativeToApi}` + (this.getAllEntitiesUrlRelativeToBase && `/${this.getAllEntitiesUrlRelativeToBase}` || '')), request, { observe: 'response', params: HttpClientUtils.getLazyLoadRequest(tableLazyLoadEvent),headers: bypassErrorInterceptor && skipErrorInterceptorHeader });
  public createEntity = (request: Partial<TFormEntity>, bypassErrorInterceptor?: boolean): Observable<boolean | TListEntity | TFormEntity | any> => this.httpClient.post<boolean | TListEntity | TFormEntity | any>(`${environment.apiUrl}/${this.baseUrlRelativeToApi}/${this.createEntityUrlRelativeToBase}`, request, { headers: bypassErrorInterceptor && skipErrorInterceptorHeader });
  public getOne = (id: number | string, bypassErrorInterceptor?: boolean): Observable<TListEntity> => this.httpClient.get<TListEntity>((`${environment.apiUrl}/${this.baseUrlRelativeToApi}/` + (this.getOneEntityUrlRelativeToBase && `${this.getOneEntityUrlRelativeToBase}/${id}` || id)), { headers: bypassErrorInterceptor && skipErrorInterceptorHeader }).pipe(map((response: TListEntity) => isArray(response) && response[0] || response));
  public getOneByFilterCriteria = (filter: any, bypassErrorInterceptor?: boolean): Observable<TListEntity> => this.httpClient.get<TListEntity>(`${environment.apiUrl}/${this.baseUrlRelativeToApi}/${this.getOneEntityByFilterCriteriaUrlRelativeToBase}`, { params: HttpClientUtils.getHttParamsFromObjectKeyValuePairs(filter), headers: bypassErrorInterceptor && skipErrorInterceptorHeader }).pipe(map((response: TListEntity) => isArray(response) && response[0] || response));
  public getCustomLazyList = (url: string, tableLazyLoadEvent: ITableLazyLoadEvent, filter: any = {}, bypassErrorInterceptor?: boolean): Observable<HttpResponse<TListEntity[]>> => this.httpClient.get<TListEntity[]>(url, { observe: 'response', params: HttpClientUtils.getLazyLoadRequestParams(tableLazyLoadEvent, filter), headers: bypassErrorInterceptor && skipErrorInterceptorHeader });
  
  public updateEntity = (id: number | string, request: Partial<TFormEntity>, bypassErrorInterceptor?: boolean): Observable<boolean | string | TListEntity | TFormEntity | any> => this.httpClient.put<boolean | string | TListEntity | TFormEntity | any>(`${environment.apiUrl}/${this.baseUrlRelativeToApi}/${this.updateEntityUrlRelativeToBase}/${id}`, request, { headers: bypassErrorInterceptor && skipErrorInterceptorHeader });
  public deleteEntity = (id: number | string, bypassErrorInterceptor?: boolean): Observable<boolean | TListEntity | TFormEntity | any> => this.httpClient.delete<boolean | TListEntity | TFormEntity | any>(`${environment.apiUrl}/${this.baseUrlRelativeToApi}/${this.deleteEntityUrlRelativeToBase}/${id}`, { headers: bypassErrorInterceptor && skipErrorInterceptorHeader });
  public deleteEntities = (ids: Array<number | string>, bypassErrorInterceptor?: boolean): Observable<boolean | any> => this.httpClient.put<boolean | any>(`${environment.apiUrl}/${this.baseUrlRelativeToApi}/${this.deleteEntitiesUrlRelativeToBase}`, ids, { headers: bypassErrorInterceptor && skipErrorInterceptorHeader });
  public exportAll = (columns: { columnLabel: string, columnProperty: string }[], { sortField, sortOrder }: ITableLazyLoadEvent, filter: any = {}, bypassErrorInterceptor?: boolean): Observable<HttpResponse<Blob>> => this.httpClient.post((`${environment.apiUrl}/${this.baseUrlRelativeToApi}` + (this.exportAllEntitiesUrlRelativeToBase && `/${this.exportAllEntitiesUrlRelativeToBase}` || '')), columns, { observe: 'response', responseType: 'blob', params: HttpClientUtils.getLazyLoadRequestParams({ sortField, sortOrder }, filter), headers: bypassErrorInterceptor && skipErrorInterceptorHeader });
  public getCustomExportList = (url: string, columns: { columnLabel: string, columnProperty: string }[], { sortField, sortOrder }: ITableLazyLoadEvent, filter: any = {}, bypassErrorInterceptor?: boolean): Observable<HttpResponse<Blob>> => this.httpClient.post(url, columns, { observe: 'response', responseType: 'blob', params: HttpClientUtils.getLazyLoadRequestParams({ sortField, sortOrder }, filter), headers: bypassErrorInterceptor && skipErrorInterceptorHeader });
  public closeEntities = (ids: Array<number | string>, bypassErrorInterceptor?: boolean): Observable<boolean | any> => this.httpClient.put<boolean | any>(`${environment.apiUrl}/${this.baseUrlRelativeToApi}/${this.closeEntityUrlRelativeToBase}`, ids, { headers: bypassErrorInterceptor && skipErrorInterceptorHeader });

  public updateEntities = (ids: Array<number | string>, bypassErrorInterceptor?: boolean): Observable<boolean | any> => this.httpClient.put<boolean | any>(`${environment.apiUrl}/${this.baseUrlRelativeToApi}/${this.updateEntityUrlRelativeToBase}`, ids, { headers: bypassErrorInterceptor && skipErrorInterceptorHeader });

  public verifyVibanExists = (request: Partial<TFormEntity>, bypassErrorInterceptor?: boolean): Observable<boolean | TListEntity | TFormEntity | any> => this.httpClient.post<boolean | TListEntity | TFormEntity | any>(`${environment.apiUrl}/${this.baseUrlRelativeToApi}/${this.verifyVibanEntitiesUrlRelativeToBase}`, request, { headers: bypassErrorInterceptor && skipErrorInterceptorHeader });
}
