import { KeycloakConfig } from 'keycloak-angular';
import { RoleName } from '../app/core/models/custom/role-name.enum';
import { FileUploadSessionFileAction } from '../app/core/models/custom/file-upload-session-file-action.enum';
import { FileUploadSessionFileType } from '../app/core/models/custom/file-upload-session-file-type.enum';
import { TextFilterType } from '../app/core/models/custom/text-filter-type.enum';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const commonEnvironmentVariables = {
  availableLanguages: [
    'en'
  ],
  defaultLanguage: 'en',
  selectedLanguageLocalStorageKey: 'boaSelectedLanguage',
  loggedInUserRoleNameLocalStorageKey: 'boaLoggedInUserRoleName',
  apiUrl: 'http://localhost/viban_api',
  keycloak: {
    config: {
      url: 'https://auth.dev.em.bank/auth',
      realm: 'viban',
      clientId: 'viban-client'
    },
    loadUserProfileAtStartUp: false,
    enableBearerInterceptor: true,
    bearerExcludedUrls: [] // Place excluded urls (that do not require authentication) here
  },
  roleBasedKeycloakConfigurationsOverrides: {
    [RoleName.SYSADMIN]: {
      realm: 'viban'
    },
    [RoleName.SYSTOSYS]: {
      realm: 'viban'
    }
  } as { [key: string]: Partial<KeycloakConfig> },
  redirectRootUriAfterLogin: `${window.location.protocol}//${window.location.host}/#`,
  redirectUriAfterLogout: `${window.location.protocol}//${window.location.host}/#`,
  /**
   * Must match keycloak's role names, used to determine whether to show sidebar
   * (left menu, for staff) or header menus (for non-staff) (see RoleName enum for available values)
   */
  staffIncludingSysAdminRoleNames: [
    RoleName.SYSADMIN,
    RoleName.SYSTOSYS,
    RoleName.FI,
    RoleName.VIEWER
  ] as RoleName[],
  /**
   * Must match keycloak's role names (see RoleName enum for available values)
   */
  ViewerRoleNames: [
    RoleName.VIEWER
  ] as RoleName[],

  sysAdminRoleNames: [
    RoleName.SYSADMIN
  ] as RoleName[],
  tableConfig: {
    pageSizeOptions: [1, 5, 10, 15, 20, 25, 50, 100],
    defaultPageSize: 10,
    defaultPageIndex: 1,
    totalRecordsResponseHeaderName: 'X-Total-Count',
    pageNumberQueryParamName: 'pageNumber',
    pageSizeQueryParamName: 'pageSize',
    sortFieldQueryParamName: 'orderBy',
    sortOrderQueryParamName: 'orderAsc',
    sortOrderQueryParamIsBoolean: true,
    livePollingIntervalInMilliseconds: 10000,
    isLivePollingSwitchShownByDefault: true,
    livePollingSwitchPosition: 'top' as 'top' | 'right' | 'left',
    isLivePollingEnabledByDefault: false,
    shouldStopLivePollingOnErrors: true
  },
  panelBaseUrlRelativeToRoot: 'panel', // reflects to: http://localhost:4200/#/panel
  staffPanelRouteUrlRelativeToPanel: 'staff', // reflects to: http://localhost:4200/#/panel/staff
  webUserPanelRouteUrlRelativeToPanel : 'staff', // reflects to: http://localhost:4200/#/panel/staff
  // webUserPanelRouteUrlRelativeToPanel: 'web-user', // reflects to: http://localhost:4200/#/panel/web-user
  dateFormat: 'DD/MM/YYYY',
  /**
   * Do NOT use `environment.dateFormat` as it is the format for placeholder or other implementations,
   * thus NOT supported from NG ZORRO ANT DESIGN date and calendar components
   * @example `<nz-date-picker [nzFormat]="environment.nzDateFormat"></nz-date-picker>`
   */
  nzDateFormat: 'yyyy/MM/dd',
  //nzDateFormat: 'dd/MM/yyyy',
  nzMonthFormat: 'MM/yyyy',
  routeDataCustomPropertyNames: {
    alwaysAllowed: 'alwaysAllowed',
    customModuleTitle: 'customModuleTitle',
    customFeatureTitle: 'customFeatureTitle',
    hasBackButton: 'hasBackButton',
    isThisModuleAFeature: 'isThisModuleAFeature',
    hideFeatureTitle: 'hideFeatureTitle'
  },
  maximumAllowedFilesToUpload: {
    [FileUploadSessionFileType.DISBURSED_INSTRUMENTS]: 1,
    [FileUploadSessionFileType.RELATED_PARTIES]: 2,
    [FileUploadSessionFileType.COLLATERAL]: 2,
    [FileUploadSessionFileType.CREDIT_STATUS_UPDATE]: 1,
    [FileUploadSessionFileType.RESTRUCTURED_CREDITS]: 2
  },
  allowedFileUploadTypes: [
    FileUploadSessionFileType.DISBURSED_INSTRUMENTS,
    FileUploadSessionFileType.RELATED_PARTIES,
    FileUploadSessionFileType.COLLATERAL,
    FileUploadSessionFileType.CREDIT_STATUS_UPDATE,
    FileUploadSessionFileType.RESTRUCTURED_CREDITS
  ],
  allowedActionsBasedOnFileType: {
    [FileUploadSessionFileType.DISBURSED_INSTRUMENTS]: [ FileUploadSessionFileAction.CREATE_UPDATE ],
    [FileUploadSessionFileType.RELATED_PARTIES]: [ FileUploadSessionFileAction.CREATE_UPDATE, FileUploadSessionFileAction.DELETE ],
    [FileUploadSessionFileType.COLLATERAL]: [ FileUploadSessionFileAction.CREATE_UPDATE, FileUploadSessionFileAction.DELETE ],
    [FileUploadSessionFileType.CREDIT_STATUS_UPDATE]: [ FileUploadSessionFileAction.CREATE_UPDATE ],
    [FileUploadSessionFileType.RESTRUCTURED_CREDITS]: [ FileUploadSessionFileAction.CREATE_UPDATE, FileUploadSessionFileAction.DELETE ]
  },
  customKeyCloakUserAttributeNames: {
    // clientId: 'CLIENTID',
    // roleId: 'ROLEID',
    // subjectId: 'SUBJECTID',
    FI_ID:'FI_ID'
  },
  fileValidationStatusPollingIntervalInMilliseconds: 5000,
  maximumFileUploadSizeInBytes: 128 * 1024 * 1024, // i.e.: 128 MB,
  defaultTextFilterType: TextFilterType.CONTAINS,
  /**
   * Used on date input text validations, while this pattern is of format "DD/MM/YYYY"
   * @example
   * datePickerFormControlRef.setValidators(Validators.pattern(environment.customDateInputTextPatternRegex));
   */
  customDateInputTextPatternRegex: `(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\\d\\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)`
};
