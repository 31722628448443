import { Component, Input } from '@angular/core';
import { TextFilterType } from '../../core/models/custom/text-filter-type.enum';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'boa-advanced-filter-field',
  templateUrl: './advanced-filter-field.component.html',
  styleUrls: ['./advanced-filter-field.component.scss']
})
export class AdvancedFilterFieldComponent {
  textFilterTypes: { label: string, value: TextFilterType }[] = Object.keys(TextFilterType).map((key: string) => ({ label: key, value: TextFilterType[key] }));
  @Input()
  label!: string;
  @Input()
  typeSelectorWidth = '100px';
  @Input()
  readonly = false;
  @Input()
  autocomplete = false;
  @Input()
  autocapitalize = false;
  @Input()
  inputType: 'text' | 'boolean' = 'text';
  @Input()
  inputFormControlRef!: FormControl;
  @Input()
  filterTypeFormControlRef!: FormControl;
}
