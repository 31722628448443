import { HttpParams } from '@angular/common/http';
import { ITableLazyLoadEvent } from '../models/custom/table-lazy-load-event.model';
import { environment } from '../../../environments/environment';

export class HttpClientUtils {
  /**
   * @param object key-value pairs object, used to create http query params with key as param name and value as param value
   * @param existingHttpParamsInstance existing `HttpParams` instance to use for appending new query params based on `object`
   * @returns HttpParams instance with generated query params based on `object`'s key-value pairs
   * @example
   * public getDepartmentsOfHeadId = (headId: number): Observable<Department[]> => this.http.get<Department[]>(`${this.url}/departments`, { params: HttpClientUtils.getHttParamsFromObjectKeyValuePairs({ headId }) });
   */
  public static getHttParamsFromObjectKeyValuePairs(object: { [key: string]: any | Array<any> | any[] }, existingHttpParamsInstance?: HttpParams): HttpParams {
    let httpParams: HttpParams = existingHttpParamsInstance || new HttpParams();
    if (object) {
      Object.keys(object).forEach((key: string) => {
        if (object[key] !== null && object[key] !== undefined) {
          if (Array.isArray(object[key])) {
            object[key].forEach((item: any, index: number) => httpParams = index ? httpParams.append(key, item) : httpParams.set(key, item));
          } else {
            httpParams = httpParams.set(key, object[key]);
          }
        }
      });
    }
    return httpParams;
  }

  public static getLazyLoadRequest(tableLazyLoadEvent: ITableLazyLoadEvent): HttpParams {
    return HttpClientUtils.getHttParamsFromObjectKeyValuePairs({
      [environment.tableConfig.pageNumberQueryParamName]: tableLazyLoadEvent.pageNumber,
      [environment.tableConfig.pageSizeQueryParamName]: tableLazyLoadEvent.pageSize,
      [environment.tableConfig.sortFieldQueryParamName]: tableLazyLoadEvent.sortField,
      [environment.tableConfig.sortOrderQueryParamName]: environment.tableConfig.sortOrderQueryParamIsBoolean ? (tableLazyLoadEvent.sortOrder === 'asc' ? true : tableLazyLoadEvent.sortOrder === 'desc' ? false : null) : tableLazyLoadEvent.sortOrder,
    });
  }

  public static getLazyLoadRequestParams(tableLazyLoadEvent: ITableLazyLoadEvent, filter: any = {}): HttpParams {
    if (filter) {
      Object.keys(filter).forEach((key: string) => {
        if (filter[key] === '' || filter[key] === null || filter[key] === undefined) {
          delete filter[key];
          if (Object.keys(filter).includes(`${key}.filterType`)) {
            delete filter[`${key}.filterType`];
          }
        }
      });
    }

    return HttpClientUtils.getHttParamsFromObjectKeyValuePairs({
      [environment.tableConfig.pageNumberQueryParamName]: tableLazyLoadEvent.pageNumber,
      [environment.tableConfig.pageSizeQueryParamName]: tableLazyLoadEvent.pageSize,
      [environment.tableConfig.sortFieldQueryParamName]: tableLazyLoadEvent.sortField,
      [environment.tableConfig.sortOrderQueryParamName]: environment.tableConfig.sortOrderQueryParamIsBoolean ? (tableLazyLoadEvent.sortOrder === 'asc' ? true : tableLazyLoadEvent.sortOrder === 'desc' ? false : null) : tableLazyLoadEvent.sortOrder,
      ...filter
    });
  }
}
