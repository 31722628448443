import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ContentChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../core/utils/utils';
import { environment } from '../../../environments/environment';
import { ITableColumn, ITableColumnTagType } from '../../core/models/custom/table-column.model';
import { ITableLazyLoadEvent } from '../../core/models/custom/table-lazy-load-event.model';
import { Directive } from '@angular/core';
import { SystemUserStatus } from '../../core/models/custom/system-user-status.enum';
import { FileUploadSessionStatus } from '../../core/models/custom/file-upload-session-status.enum';
import { FormControl } from '@angular/forms';
import { CrudEntity } from '../../core/utils/crud-entity';
import { AuthenticationEventType } from '../../core/models/custom/authentication-event-type.enum';
import { IrregularityStatus } from '../../core/models/custom/irregularity-status.enum';

@Directive({
  selector: '[tableFilter]',
})
export class TableFilterDirective { }

@Directive({
  selector: '[tableMainActions]',
})
export class TableMainActionsDirective { }

@Directive({
  selector: '[tableRowActions]',
})
export class TableRowActionsDirective { }

@Directive({
  selector: '[tableLeadingColumns]',
})
export class TableLeadingColumnsDirective { }

@Directive({
  selector: '[tableTrailingColumns]',
})
export class TableTrailingColumnsDirective { }

@Directive({
  selector: '[tableLeadingRows]',
})
export class TableLeadingRowsDirective { }

@Directive({
  selector: '[tableTrailingRows]',
})
export class TableTrailingRowsDirective { }

@Component({
  selector: 'boa-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input()
  componentInstance?: CrudEntity<any, any, any>;
  @Input()
  tableTitle!: string;
  @Input()
  isListLoading!: boolean;
  @Input()
  isTableExportEnabled!: boolean;
  @Input()
  isTableExportButtonShown!: boolean;

  @Input()
  tableExportIcon = 'file-excel';
  @Input()
  customTableExportFn!: () => void | never;
  @Input()
  isLivePollingEnabled!: boolean;
  @Input()
  isLivePollingSwitchShown!: boolean;
  @Input()
  livePollingSwitchPosition!: 'left' | 'right' | 'top';
  @Input()
  livePollingSwitchedOnLabel = 'liveUpdates';
  @Input()
  livePollingSwitchedOffLabel = 'liveUpdates';
  @Input()
  data!: any[];
  @Input()
  totalRecords!: number;
  @Input()
  columns!: ITableColumn[];
  @Input()
  selectedRowsFormControlRef!: FormControl;
  /**
   * If, explicitly, set to `null` or `undefined`, the full object will be used for selection and comparision of which is selected.
   * @example
   * <boa-table [componentInstance]="componentInstance" [selectedRowsFormControlRef]="filterFormGroup?.get('selectedRows')" [rowSelectionPropertyName]="null"></boa-table>
   */
  @Input()
  rowSelectionPropertyName: string | null | undefined = 'sid';
  @Input()
  rowSelectionCondition!: (row: any) => boolean;
  @Input()
  useLazyLoad = true;
  @Input()
  usePagination = true;
  @Input()
  hidePaginationOnSinglePage = false;
  @Input()
  paginationPosition: 'top' | 'bottom' | 'both' = 'bottom';
  @Input()
  pageSizeOptions = environment.tableConfig.pageSizeOptions;
  @Input()
  defaultPageSize = environment.tableConfig.defaultPageSize;
  @Input()
  defaultPageIndex = environment.tableConfig.defaultPageIndex;
  @Input()
  showNoResultsMessage = true;
  @Input()
  noResultsMessageTranslateKey = 'noResults';
  @Input()
  borderedTable = true;
  @Input()
  showPageSizeChanger = true;
  @Input()
  tableSize: 'middle' | 'default' | 'small' = 'default';
  @Input()
  fireInitialLazyLoadEvent = true;
  @Output()
  lazyLoad: EventEmitter<ITableLazyLoadEvent> = new EventEmitter<ITableLazyLoadEvent>();
  @Output()
  livePollingSwitchChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  rowSelectionChange: EventEmitter<void> = new EventEmitter<void>();
  originalData!: any[]; // In case `useLazyLoad` is false, an original list is needed for FrontEnd-side sorting (to revert back to original list if sorting is cleared)
  utils = Utils; // Used in HTML
  selectedPageSize!: number;
  selectedPageIndex!: number;
  selectedSortField!: string;
  selectedSortOrder!: 'asc' | 'desc';
  @ContentChild(TableFilterDirective, { read: TemplateRef, static: false }) tableFilterTemplateRef: TemplateRef<any>;
  @ContentChild(TableMainActionsDirective, { read: TemplateRef, static: false }) tableMainActionsTemplateRef: TemplateRef<any>;
  @ContentChild(TableRowActionsDirective, { read: TemplateRef, static: false }) tableRowActionsTemplateRef: TemplateRef<any>;
  @ContentChild(TableLeadingColumnsDirective, { read: TemplateRef, static: false }) tableLeadingColumnsTemplateRef: TemplateRef<any>;
  @ContentChild(TableTrailingColumnsDirective, { read: TemplateRef, static: false }) tableTrailingColumnsTemplateRef: TemplateRef<any>;
  @ContentChild(TableLeadingRowsDirective, { read: TemplateRef, static: false }) tableLeadingRowsTemplateRef: TemplateRef<any>;
  @ContentChild(TableTrailingRowsDirective, { read: TemplateRef, static: false }) tableTrailingRowsTemplateRef: TemplateRef<any>;


  constructor(
    public translateService: TranslateService
  ) { }

  ngOnInit() {
    this.selectedPageSize = this.defaultPageSize;
    this.selectedPageIndex = this.defaultPageIndex;
    if (this.useLazyLoad && this.fireInitialLazyLoadEvent) {
      this.emitLazyLoadEvent();
    }
  }

  private emitLazyLoadEvent(): void {
    const tableLazyLoadEvent: ITableLazyLoadEvent = {
      pageSize: this.selectedPageSize,
      pageNumber: this.selectedPageIndex,
      sortField: this.selectedSortField,
      sortOrder: this.selectedSortOrder
    };
    this.lazyLoad.emit(tableLazyLoadEvent);
    if (this.selectedRowsFormControlRef) {
      this.selectedRowsFormControlRef.setValue([]);
    }
    if (this.componentInstance && this.componentInstance.loadList) {
      this.componentInstance.loadList(tableLazyLoadEvent);
    }
  }

  public onPageSizeChange(selectedPageSize: number): void {
    this.selectedPageSize = selectedPageSize;
    if (this.useLazyLoad) {
      this.emitLazyLoadEvent();
    }
  }

  public onPageIndexChange(selectedPageIndex: number): void {
    this.selectedPageIndex = selectedPageIndex;
    if (this.useLazyLoad) {
      this.emitLazyLoadEvent();
    }
  }

  public onSortChange({ key, value }: { key: string, value: string }): void {
    this.selectedSortOrder = value === 'ascend' ? 'asc' : value === 'descend' ? 'desc' : null;
    this.selectedSortField = this.selectedSortOrder && key; // No sort field must be set if sort order is null (tri-state order, asc, desc, none/null)
    if (this.useLazyLoad) {
      this.emitLazyLoadEvent();
    } else {
      if (!this.originalData && (this.data || this.componentInstance && this.componentInstance.results) && (this.data || this.componentInstance && this.componentInstance.results).length) {
        this.originalData = [ ...(this.data || this.componentInstance && this.componentInstance.results) ];
      }
      if (this.originalData && this.originalData.length) { // Sort from Front-End side
        if (this.selectedSortField && this.selectedSortOrder) {
          (this.data || this.componentInstance && this.componentInstance.results).sort((a: any, b: any) => Utils.getPropertyValue(a, this.selectedSortField) > Utils.getPropertyValue(b, this.selectedSortField) ? (this.selectedSortOrder === 'asc' && 1 || -1) : (this.selectedSortOrder === 'asc' && -1 || 1));
          if (this.data) {
            this.data = [ ...this.data ];
          } else if (this.componentInstance && this.componentInstance.results) {
            this.componentInstance.results = [ ...this.componentInstance.results ];
          }
        } else {
          if (this.data) {
            this.data = [ ...this.originalData ];
          } else if (this.componentInstance && this.componentInstance.results) {
            this.componentInstance.results = [ ...this.originalData ];
          }
        }
      }
    }
  }

  public getTagTypeByString(value: ITableColumnTagType | string | number | boolean | null | undefined, tagTypeEnumTypeDef?: any): ITableColumnTagType | null | undefined {
    switch (`${value}`) {
      case tagTypeEnumTypeDef === IrregularityStatus && `${IrregularityStatus.IN_PROGRESS}`: return 'magenta';
      case tagTypeEnumTypeDef === IrregularityStatus && `${IrregularityStatus.OPENED}`: return 'cyan';
      case tagTypeEnumTypeDef === IrregularityStatus && `${IrregularityStatus.CLOSED}`:
      case tagTypeEnumTypeDef === FileUploadSessionStatus && `${FileUploadSessionStatus.NOT_STARTED}`:
      case tagTypeEnumTypeDef === FileUploadSessionStatus && `${FileUploadSessionStatus.UPLOADING}`:
      case tagTypeEnumTypeDef === FileUploadSessionStatus && `${FileUploadSessionStatus.IN_PROCESS}`:
      case tagTypeEnumTypeDef === FileUploadSessionStatus && `${FileUploadSessionStatus.PENDING}`:
      case tagTypeEnumTypeDef === SystemUserStatus && `${SystemUserStatus.FOR_APPROVAL}`: return 'info';
      case tagTypeEnumTypeDef === IrregularityStatus && `${IrregularityStatus.SOLVED}`:
      case tagTypeEnumTypeDef === FileUploadSessionStatus && `${FileUploadSessionStatus.COMPLETED}`:
      case tagTypeEnumTypeDef === FileUploadSessionStatus && `${FileUploadSessionStatus.DOWNLOADED}`:
      case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.LOGIN}`:
      case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.CLIENT_LOGIN}`:
      case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.IDENTITY_PROVIDER_FIRST_LOGIN}`:
      case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.IDENTITY_PROVIDER_LOGIN}`:
      case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.IDENTITY_PROVIDER_POST_LOGIN}`:
      case tagTypeEnumTypeDef === SystemUserStatus && `${SystemUserStatus.APPROVED}`: return 'success';
      case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.LOGOUT}`: return 'warning';
      case tagTypeEnumTypeDef === FileUploadSessionStatus && `${FileUploadSessionStatus.CANCELED}`:
      case tagTypeEnumTypeDef === FileUploadSessionStatus && `${FileUploadSessionStatus.STRUCTURE_VALIDATION}`:
      case tagTypeEnumTypeDef === FileUploadSessionStatus && `${FileUploadSessionStatus.VALUE_VALIDATION}`:
      case tagTypeEnumTypeDef === FileUploadSessionStatus && `${FileUploadSessionStatus.ERROR}`:
      case tagTypeEnumTypeDef === AuthenticationEventType && (Object.values(AuthenticationEventType) as string[]).includes(`${value}`) && `${value}`.includes('ERROR') && `${value}`:
      case tagTypeEnumTypeDef === SystemUserStatus && `${SystemUserStatus.REFUSED}`: return 'error';
    }
  }

  public getIconNameByTagType(tagType: ITableColumnTagType | string | number | boolean | null | undefined, tagTypeEnumTypeDef?: any): string {
    if (tagType !== null && tagType !== undefined) {
      switch (`${tagType}`) {
        case tagTypeEnumTypeDef === IrregularityStatus && `${IrregularityStatus.IN_PROGRESS}`: return 'sync';
        case tagTypeEnumTypeDef === IrregularityStatus && `${IrregularityStatus.OPENED}`: return 'folder-open';
        case tagTypeEnumTypeDef === IrregularityStatus && `${IrregularityStatus.CLOSED}`: return 'folder';
        case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.LOGIN}`:
        case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.LOGIN_ERROR}`:
        case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.CLIENT_LOGIN}`:
        case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.CLIENT_LOGIN_ERROR}`:
        case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.IDENTITY_PROVIDER_FIRST_LOGIN}`:
        case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.IDENTITY_PROVIDER_FIRST_LOGIN_ERROR}`:
        case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.IDENTITY_PROVIDER_LOGIN}`:
        case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.IDENTITY_PROVIDER_LOGIN_ERROR}`:
        case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.IDENTITY_PROVIDER_POST_LOGIN}`:
        case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.IDENTITY_PROVIDER_POST_LOGIN_ERROR}`: return 'login';
        case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.LOGOUT}`:
        case tagTypeEnumTypeDef === AuthenticationEventType && `${AuthenticationEventType.LOGOUT_ERROR}`: return 'logout';
        case tagTypeEnumTypeDef === FileUploadSessionStatus && `${FileUploadSessionStatus.NOT_STARTED}`:
        case tagTypeEnumTypeDef === FileUploadSessionStatus && `${FileUploadSessionStatus.UPLOADING}`:
        case tagTypeEnumTypeDef === FileUploadSessionStatus && `${FileUploadSessionStatus.IN_PROCESS}`:
        case tagTypeEnumTypeDef === FileUploadSessionStatus && `${FileUploadSessionStatus.PENDING}`:
        case tagTypeEnumTypeDef === SystemUserStatus && `${SystemUserStatus.FOR_APPROVAL}`: return 'clock-circle';
        case tagTypeEnumTypeDef === FileUploadSessionStatus && `${FileUploadSessionStatus.CANCELED}`: return 'stop';
        case 'info': return 'info-circle';
        case 'success': return 'check-circle';
        case 'warning': return 'exclamation-circle';
        case 'error': return 'close-circle';
        default: return this.getIconNameByTagType(this.getTagTypeByString(tagType, tagTypeEnumTypeDef));
      }
    }
  }

  public checkOrUncheckAllResults(value: boolean): void {
    this.selectedRowsFormControlRef.setValue(value && this.getListOfSelectableRowsBasedOnCondition().map((selectableRow: any) => this.rowSelectionPropertyName && Utils.getPropertyValue(selectableRow, this.rowSelectionPropertyName) || selectableRow) || []);
    this.rowSelectionChange.emit();
    if (this.componentInstance) {
      this.componentInstance.stopTableLiveDataPolling();
    }
  }

  public refreshCheckedResultsList(checked: boolean, row: any): void {
    this.selectedRowsFormControlRef.setValue(checked && [ ...this.selectedRowsFormControlRef.value, (this.rowSelectionPropertyName && Utils.getPropertyValue(row, this.rowSelectionPropertyName) || row) ] || this.selectedRowsFormControlRef.value.filter((selectedRow: any) => !Utils.deepCompare(this.rowSelectionPropertyName && Utils.getPropertyValue(row, this.rowSelectionPropertyName) || row, selectedRow)));
    this.rowSelectionChange.emit();
    if (this.componentInstance) {
      this.componentInstance.stopTableLiveDataPolling();
    }
  }

  public getListOfSelectableRowsBasedOnCondition = (): any[] => this.rowSelectionCondition && (((this.data || this.componentInstance && this.componentInstance.results) || []).filter(this.rowSelectionCondition)) || (this.data || this.componentInstance && this.componentInstance.results) || [];

  public livePollingSwitchChangeHandler(): void {
    const changedValue: boolean = !(this.isLivePollingEnabled || (this.componentInstance && this.componentInstance.isLivePollingEnabled));
    this.livePollingSwitchChange.emit(changedValue);
    if (this.componentInstance) {
      if (changedValue) {
        this.componentInstance.startTableLiveDataPolling();
      } else {
        this.componentInstance.stopTableLiveDataPolling();
      }
    }
  }
}
