import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { RoleName } from '../models/custom/role-name.enum';
import { Utils } from '../utils/utils';
import { KeycloakProfile } from 'keycloak-js';
import { IModuleDTO } from '../models/generated/module-dto.model';
import { ISimpleSubjectDTO } from '../models/generated/simple-subject-dto.model';

export interface IUserProfile extends KeycloakProfile {
  attributes?: any;
  isStaff?: boolean;
  isSysAdmin?: boolean;
  /**
   * Include all roles that come from keycloak, including i.e.: `'manage-account'`
   */
  allRolesIncludingKeycloakSpecificOnes?: string[];
  /**
   * Only roles that come from keycloak that are valid roles based on `RoleName` enum
   */
  roles?: RoleName[];
  menus?: IModuleDTO[];
  subject?: ISimpleSubjectDTO;
}

@Injectable({
  providedIn: 'root'
})
export class KeycloakGuard extends KeycloakAuthGuard implements CanActivate {
  constructor(
    private translateService: TranslateService,
    protected router: Router,
    protected keycloakAngular: KeycloakService // Do NOT rename this to "keycloakService" or any other, it needs to be with the name of "keycloakAngular", in order to override the one from "KeycloakAuthGuard" class this guard extends from. We need that exact reference, because we override one or more methods later on, in this file.
  ) {
    super(router, keycloakAngular);
  }

  private setKeycloakMethodOverrides(): void {
    const originalCreateLoginUrlFunction = this.keycloakAngular.getKeycloakInstance().createLoginUrl;
    this.keycloakAngular.getKeycloakInstance().createLoginUrl = (options) => originalCreateLoginUrlFunction(options).replace('ui_locales', 'kc_locale');
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      if (state && state.url && state.url.startsWith('/role')) {
        this.keycloakAngular.init(Utils.getKeycloakConfigurations(state.url.split('/')[2].toUpperCase() as RoleName)).then(() => { // i.e.: splitting /SYSADMIN/panel to get [ '', 'role', 'SYSADMIN', 'panel' ], so we select 2th element (index 1)
          localStorage.setItem(environment.loggedInUserRoleNameLocalStorageKey, state.url.split('/')[2].toUpperCase());
          if (!this.authenticated) {
            this.setKeycloakMethodOverrides();
            this.keycloakAngular.getKeycloakInstance().login({ locale: this.translateService.currentLang, redirectUri: `${environment.redirectRootUriAfterLogin}/${environment.panelBaseUrlRelativeToRoot}` });
            return resolve(false);
          }
          const requiredRoles: RoleName[] = route.data.roles;
          if (!requiredRoles || !requiredRoles.length) {
            return resolve(true);
          } else {
            if (!this.roles || !this.roles.length) {
              resolve(false);
            }
            resolve(Utils.hasRole(this.roles as RoleName[], requiredRoles));
          }
        });
      } else {
        if (!this.authenticated) {
          this.setKeycloakMethodOverrides();
          this.keycloakAngular.getKeycloakInstance().login({ locale: this.translateService.currentLang, redirectUri: `${environment.redirectRootUriAfterLogin}${state.url}` });
          return resolve(false);
        }
        const requiredRoles: RoleName[] = route.data.roles;
        if (!requiredRoles || !requiredRoles.length) {
          return resolve(true);
        } else {
          if (!this.roles || !this.roles.length) {
            resolve(false);
          }
          resolve(Utils.hasRole(this.roles as RoleName[], requiredRoles));
        }
      }
    });
  }
}
