export enum FileUploadSessionStatus {
  NOT_STARTED = 'SS_N_S',
  UPLOADING = 'SS_U',
  STRUCTURE_VALIDATION = 'SS_S_V',
  IN_PROCESS = 'SS_I_P',
  COMPLETED = 'SS_C',
  DOWNLOADED = 'SS_D',
  ERROR = 'SS_E',
  VALUE_VALIDATION = 'SS_V',
  PENDING = 'SS_P',
  CANCELED = 'SS_A'
}
