/**
 * MUST match Keycloak's event names over at https://www.keycloak.org/docs-api/6.0/javadocs/org/keycloak/events/EventType.html
 */
export enum AuthenticationEventType {
  CLIENT_DELETE = 'CLIENT_DELETE',
  CLIENT_DELETE_ERROR = 'CLIENT_DELETE_ERROR',
  CLIENT_INFO = 'CLIENT_INFO',
  CLIENT_INFO_ERROR = 'CLIENT_INFO_ERROR',
  CLIENT_INITIATED_ACCOUNT_LINKING = 'CLIENT_INITIATED_ACCOUNT_LINKING',
  CLIENT_INITIATED_ACCOUNT_LINKING_ERROR = 'CLIENT_INITIATED_ACCOUNT_LINKING_ERROR',
  CLIENT_LOGIN = 'CLIENT_LOGIN',
  CLIENT_LOGIN_ERROR = 'CLIENT_LOGIN_ERROR',
  CLIENT_REGISTER = 'CLIENT_REGISTER',
  CLIENT_REGISTER_ERROR = 'CLIENT_REGISTER_ERROR',
  CLIENT_UPDATE = 'CLIENT_UPDATE',
  CLIENT_UPDATE_ERROR = 'CLIENT_UPDATE_ERROR',
  CODE_TO_TOKEN = 'CODE_TO_TOKEN',
  CODE_TO_TOKEN_ERROR = 'CODE_TO_TOKEN_ERROR',
  CUSTOM_REQUIRED_ACTION = 'CUSTOM_REQUIRED_ACTION',
  CUSTOM_REQUIRED_ACTION_ERROR = 'CUSTOM_REQUIRED_ACTION_ERROR',
  EXECUTE_ACTION_TOKEN = 'EXECUTE_ACTION_TOKEN',
  EXECUTE_ACTION_TOKEN_ERROR = 'EXECUTE_ACTION_TOKEN_ERROR',
  EXECUTE_ACTIONS = 'EXECUTE_ACTIONS',
  EXECUTE_ACTIONS_ERROR = 'EXECUTE_ACTIONS_ERROR',
  FEDERATED_IDENTITY_LINK = 'FEDERATED_IDENTITY_LINK',
  FEDERATED_IDENTITY_LINK_ERROR = 'FEDERATED_IDENTITY_LINK_ERROR',
  IDENTITY_PROVIDER_FIRST_LOGIN = 'IDENTITY_PROVIDER_FIRST_LOGIN',
  IDENTITY_PROVIDER_FIRST_LOGIN_ERROR = 'IDENTITY_PROVIDER_FIRST_LOGIN_ERROR',
  IDENTITY_PROVIDER_LINK_ACCOUNT = 'IDENTITY_PROVIDER_LINK_ACCOUNT',
  IDENTITY_PROVIDER_LINK_ACCOUNT_ERROR = 'IDENTITY_PROVIDER_LINK_ACCOUNT_ERROR',
  IDENTITY_PROVIDER_LOGIN = 'IDENTITY_PROVIDER_LOGIN',
  IDENTITY_PROVIDER_LOGIN_ERROR = 'IDENTITY_PROVIDER_LOGIN_ERROR',
  IDENTITY_PROVIDER_POST_LOGIN = 'IDENTITY_PROVIDER_POST_LOGIN',
  IDENTITY_PROVIDER_POST_LOGIN_ERROR = 'IDENTITY_PROVIDER_POST_LOGIN_ERROR',
  IDENTITY_PROVIDER_RESPONSE = 'IDENTITY_PROVIDER_RESPONSE',
  IDENTITY_PROVIDER_RESPONSE_ERROR = 'IDENTITY_PROVIDER_RESPONSE_ERROR',
  IDENTITY_PROVIDER_RETRIEVE_TOKEN = 'IDENTITY_PROVIDER_RETRIEVE_TOKEN',
  IDENTITY_PROVIDER_RETRIEVE_TOKEN_ERROR = 'IDENTITY_PROVIDER_RETRIEVE_TOKEN_ERROR',
  IMPERSONATE = 'IMPERSONATE',
  IMPERSONATE_ERROR = 'IMPERSONATE_ERROR',
  INTROSPECT_TOKEN = 'INTROSPECT_TOKEN',
  INTROSPECT_TOKEN_ERROR = 'INTROSPECT_TOKEN_ERROR',
  INVALID_SIGNATURE = 'INVALID_SIGNATURE',
  INVALID_SIGNATURE_ERROR = 'INVALID_SIGNATURE_ERROR',
  LOGIN = 'LOGIN',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGOUT = 'LOGOUT',
  LOGOUT_ERROR = 'LOGOUT_ERROR',
  PERMISSION_TOKEN = 'PERMISSION_TOKEN',
  PERMISSION_TOKEN_ERROR = 'PERMISSION_TOKEN_ERROR',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR',
  REGISTER = 'REGISTER',
  REGISTER_ERROR = 'REGISTER_ERROR',
  REGISTER_NODE = 'REGISTER_NODE',
  REGISTER_NODE_ERROR = 'REGISTER_NODE_ERROR',
  REMOVE_FEDERATED_IDENTITY = 'REMOVE_FEDERATED_IDENTITY',
  REMOVE_FEDERATED_IDENTITY_ERROR = 'REMOVE_FEDERATED_IDENTITY_ERROR',
  REMOVE_TOTP = 'REMOVE_TOTP',
  REMOVE_TOTP_ERROR = 'REMOVE_TOTP_ERROR',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR',
  RESTART_AUTHENTICATION = 'RESTART_AUTHENTICATION',
  RESTART_AUTHENTICATION_ERROR = 'RESTART_AUTHENTICATION_ERROR',
  REVOKE_GRANT = 'REVOKE_GRANT',
  REVOKE_GRANT_ERROR = 'REVOKE_GRANT_ERROR',
  SEND_IDENTITY_PROVIDER_LINK = 'SEND_IDENTITY_PROVIDER_LINK',
  SEND_IDENTITY_PROVIDER_LINK_ERROR = 'SEND_IDENTITY_PROVIDER_LINK_ERROR',
  SEND_RESET_PASSWORD = 'SEND_RESET_PASSWORD',
  SEND_RESET_PASSWORD_ERROR = 'SEND_RESET_PASSWORD_ERROR',
  SEND_VERIFY_EMAIL = 'SEND_VERIFY_EMAIL',
  SEND_VERIFY_EMAIL_ERROR = 'SEND_VERIFY_EMAIL_ERROR',
  TOKEN_EXCHANGE = 'TOKEN_EXCHANGE',
  TOKEN_EXCHANGE_ERROR = 'TOKEN_EXCHANGE_ERROR',
  UNREGISTER_NODE = 'UNREGISTER_NODE',
  UNREGISTER_NODE_ERROR = 'UNREGISTER_NODE_ERROR',
  UPDATE_EMAIL = 'UPDATE_EMAIL',
  UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_ERROR',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR',
  UPDATE_TOTP = 'UPDATE_TOTP',
  UPDATE_TOTP_ERROR = 'UPDATE_TOTP_ERROR',
  USER_INFO_REQUEST = 'USER_INFO_REQUEST',
  USER_INFO_REQUEST_ERROR = 'USER_INFO_REQUEST_ERROR',
  VALIDATE_ACCESS_TOKEN = 'VALIDATE_ACCESS_TOKEN',
  VALIDATE_ACCESS_TOKEN_ERROR = 'VALIDATE_ACCESS_TOKEN_ERROR',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR'
}
