import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

export const skipErrorInterceptorHeaderKey = 'X-Skip-Error-Interceptor';
export const skipErrorInterceptorHeader: HttpHeaders = new HttpHeaders().set(skipErrorInterceptorHeaderKey, '');

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private keycloakService: KeycloakService, private nzNotificationService: NzNotificationService, private translateService: TranslateService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return req.headers.has(skipErrorInterceptorHeaderKey) && next.handle(req) || next.handle(req).pipe(
      catchError((error: Error | HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this.keycloakService.logout();
          } else if (error.error && (error.error.Message || error.error.title) || error.message) {
            this.nzNotificationService.error(`Error ${error.status}`, this.translateService.instant(error.error && (error.error.Message || error.error.title) || error.message || 'unknownError'));
          }
        }
        return throwError(error);
      })
    );
  }
}
