import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SubjectDTO } from '../models/generated/subject-dto.model';
import { CrudService } from './crud.service';
import { Observable } from 'rxjs';
import { ISimpleSubjectDTO } from '../models/generated/simple-subject-dto.model';
import { environment } from '../../../environments/environment';
import { HttpClientUtils } from '../utils/http-client-utils';

@Injectable({
  providedIn: 'root'
})
export class SubjectService extends CrudService<SubjectDTO, SubjectDTO> {
  protected baseUrlRelativeToApi = 'Subject';

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  public getSubjectsForDropdown = null
  // (subjectId?: number): Observable<ISimpleSubjectDTO[]> => this.httpClient.get<ISimpleSubjectDTO[]>(`${environment.apiUrl}/${this.baseUrlRelativeToApi}/GetForDropbox`, subjectId !== null && subjectId !== undefined && { params: HttpClientUtils.getHttParamsFromObjectKeyValuePairs({ subjectId }) });
}
