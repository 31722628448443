import { commonEnvironmentVariables } from './environment.common';
import { KeycloakOptions, KeycloakConfig } from 'keycloak-angular';

export const environment = {
  ...commonEnvironmentVariables, // Override `commonEnvironmentVariable` property values below this row, MUST match same object structure as `commonEnvironmentVariable` (see `src/environments/environment.common.ts` file)
  production: false,
  mock: false,
  apiUrl: 'https://viban.dev.em.bank/viban_api',
  keycloak: {
    ...commonEnvironmentVariables.keycloak,
    config: {
      ...(commonEnvironmentVariables.keycloak.config as KeycloakConfig), // Override keycloak configs below, i.e.: url, realm name and/or realm's client name
        url: 'https://auth.dev.em.bank/auth'
      
    }
  } as KeycloakOptions
};
