import { Injectable } from '@angular/core';
import { IUserProfile } from '../guards/keycloak.guard';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { RoleName } from '../models/custom/role-name.enum';
import { environment } from '../../../environments/environment';
import { Utils } from '../utils/utils';
import { IModuleDTO } from '../models/generated/module-dto.model';
import { Observable, from } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { NzI18nInterface, en_GB } from 'ng-zorro-antd';
import { IFeatureModuleDTO } from '../models/generated/feature-module-dto.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ISimpleSubjectDTO } from '../models/generated/simple-subject-dto.model';
import { SubjectService } from './subject.service';
import { IPrivilegesDTO } from '../models/generated/privileges-dto.model';
import { IComponentDTO } from '../models/generated/component-dto.model';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {
  currentUserProfile!: IUserProfile;
  currentUserProfileLoading = false;
  currentUserProfileLoaded = false;
  currentActiveModule!: IModuleDTO;
  currentActiveModuleCustomTitle!: string;
  currentActiveFeatureModule!: IFeatureModuleDTO;
  currentActiveFeatureCustomTitle!: string;
  currentActivatedRoute!: ActivatedRoute;
  currentActiveRouteBackButtonDestination!: ActivatedRoute;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private keycloakService: KeycloakService,
    private httpClient: HttpClient,
    private subjectService: SubjectService
  ) { }

  private loadUserMenus = (): Observable<IModuleDTO[]> => this.httpClient.get<IModuleDTO[]>(`${environment.apiUrl}/Menu/Get`);

  /**
   * @returns `route.firstChild.firstChild.firstChild.firstChild`.... until it finds the last `firstChild`, which is the current active route
   */
  private getFirstRouteChildUntilNullForRoute = (route: ActivatedRoute): ActivatedRoute => route.firstChild && this.getFirstRouteChildUntilNullForRoute(route.firstChild) || route;

  private getCustomModuleOrFeatureTitleBasedOnActiveRoute = (route: ActivatedRoute, titlePropertyName: string): string => route && (route.routeConfig && route.routeConfig.data && route.routeConfig.data[titlePropertyName] || this.getCustomModuleOrFeatureTitleBasedOnActiveRoute(route.parent, titlePropertyName)) || null;

  private getFirstParentRouteThatHasNoRedirectsOfActiveRoute = (route: ActivatedRoute): ActivatedRoute => route && (route.parent && (route.routeConfig && route.routeConfig.path && (!route.parent.routeConfig || !route.parent.routeConfig.redirectTo)) ? route.parent : this.getFirstParentRouteThatHasNoRedirectsOfActiveRoute(route.parent)) || null;

  private getMockUserProfilePromiseRequest = (): Promise<IUserProfile> => this.httpClient.get<IUserProfile>(`${environment.apiUrl}/user-profile`).toPromise();

  public setAndReturnCurrentUserProfileAndMenusIfLoggedIn(): Observable<IUserProfile> {
    this.currentUserProfileLoading = true;
    return from(
      new Promise(resolve => resolve((environment.mock && Promise.resolve(true) || this.keycloakService.isLoggedIn()).then(async (isLoggedIn: boolean) => {
        if (isLoggedIn) {
          await (environment.mock && this.getMockUserProfilePromiseRequest() || this.keycloakService.loadUserProfile()).then(async (response: KeycloakProfile) => {
            if (response) {
              this.currentUserProfile = environment.mock ? response : {
                ...response,
                allRolesIncludingKeycloakSpecificOnes: this.keycloakService.getUserRoles(),
              };
              this.currentUserProfile.roles = this.currentUserProfile.allRolesIncludingKeycloakSpecificOnes.filter((role: string) => (Object.values(RoleName) as string[]).includes(role)) as RoleName[];
              if (this.currentUserProfile.roles && this.currentUserProfile.roles.length) {
                this.currentUserProfile.isStaff = Utils.hasRole(this.currentUserProfile.roles, environment.staffIncludingSysAdminRoleNames);
                this.currentUserProfile.isSysAdmin = Utils.hasRole(this.currentUserProfile.roles, environment.sysAdminRoleNames);
              }
              await this.loadUserMenus().toPromise().then((menus: IModuleDTO[]) => this.currentUserProfile.menus = this.currentUserProfile.isStaff && [ { url: 'dashboard', name_al: 'Dashboard', name_en: 'Dashboard', icon: 'home' } , ...menus ] || menus);
              
              //await this.loadUserMenus().toPromise().then((menus: IModuleDTO[]) => this.currentUserProfile.menus = this.currentUserProfile.isStaff &&  menus  || menus);
              // if (this.currentUserProfile.attributes[environment.customKeyCloakUserAttributeNames.subjectId] !== null && this.currentUserProfile.attributes[environment.customKeyCloakUserAttributeNames.subjectId] !== undefined) {
              //   await this.subjectService.getSubjectsForDropdown(this.currentUserProfile.attributes[environment.customKeyCloakUserAttributeNames.subjectId]).toPromise().then((subjects: ISimpleSubjectDTO[]) => this.currentUserProfile.subject = subjects && subjects[0]);
              // }
            }
          });
          return this.currentUserProfile;
        } else {
          Utils.clearLastLoggedInRoleNameFromLocalStorage();
        }
      })))
    ).pipe(
      tap(
        () => this.currentUserProfileLoaded = true,
        () => this.currentUserProfileLoaded = false
      ),
      finalize(() => this.currentUserProfileLoading = false)
    );
  }

  public getNzI18nCustomLocale = (): NzI18nInterface => ({
    ...en_GB,
    Empty: {
      description: 'Nuk ka rezultate'
    },
    Pagination: {
      ...en_GB.Pagination,
      items_per_page: ''
    },
    DatePicker: {
      ...en_GB.DatePicker,
      lang: {
        ...en_GB.DatePicker.lang,
        placeholder: environment.nzDateFormat.toUpperCase(),
        rangePlaceholder: ['Datë fillimi', 'Datë mbarimi'],
        today: 'Sot',
        month: 'Muaji',
        year: 'Viti',
        timeSelect: 'Zgjidhni orën',
        dateSelect: 'Zgjidhni datën',
        monthSelect: 'Zgjidhni muajin',
        yearSelect: 'Zgjidhni vitin',
        decadeSelect: 'Zgjidhni dekadën',
        previousMonth: 'Muaji paraardhës',
        previousYear: 'Viti paraardhës',
        previousDecade: 'Dekada paraardhëse',
        previousCentury: 'Shekulli paraardhës',
        nextMonth: 'Muaji pasardhës',
        nextYear: 'Viti pasardhës',
        nextDecade: 'Dekada pasardhëse',
        nextCentury: 'Shekulli pasardhës'
      }
    }
  })

  public setSelectedModuleAndFeatureModuleBasedOnUrl(url: string): void {
    this.currentActivatedRoute = this.getFirstRouteChildUntilNullForRoute(this.route);
    this.currentActiveModule = undefined; // Reset on route change, to hide the previous module title (the route that was navigated away from)
    this.currentActiveFeatureModule = undefined; // Reset on route change, to hide the previous feature module title (the route that was navigated away from)
    this.currentActiveModuleCustomTitle = this.getCustomModuleOrFeatureTitleBasedOnActiveRoute(this.currentActivatedRoute, environment.routeDataCustomPropertyNames.customModuleTitle);
    this.currentActiveFeatureCustomTitle = this.getCustomModuleOrFeatureTitleBasedOnActiveRoute(this.currentActivatedRoute, environment.routeDataCustomPropertyNames.customFeatureTitle);
    this.currentActiveRouteBackButtonDestination = this.currentActivatedRoute && this.currentActivatedRoute.routeConfig && this.currentActivatedRoute.routeConfig.data && this.currentActivatedRoute.routeConfig.data[environment.routeDataCustomPropertyNames.hasBackButton] && this.getFirstParentRouteThatHasNoRedirectsOfActiveRoute(this.currentActivatedRoute) || null;
    if (this.currentUserProfile && this.currentUserProfile.menus && this.currentUserProfile.menus.length) {
      const baseUrl = environment.panelBaseUrlRelativeToRoot;
      const roleBasedPanelUrl = this.currentUserProfile.isStaff && environment.staffPanelRouteUrlRelativeToPanel || environment.webUserPanelRouteUrlRelativeToPanel;
      let urlSegments: string[] = url.split('/');
      if (urlSegments.indexOf(baseUrl) !== -1) {
        urlSegments.splice(urlSegments.indexOf(baseUrl), 1);
        urlSegments.splice(urlSegments.indexOf(roleBasedPanelUrl), 1);
      }

      urlSegments = urlSegments.filter((urlSegment: string) => urlSegment); // To remove empty strings
      this.currentActiveModule = this.currentUserProfile.menus.find((module: IModuleDTO) => module.url === urlSegments[0]);
      

      if (this.currentActiveModule && ((this.currentActivatedRoute.routeConfig && this.currentActivatedRoute.routeConfig.data && this.currentActivatedRoute.routeConfig.data[environment.routeDataCustomPropertyNames.isThisModuleAFeature] && this.currentActiveModule.featureModules && this.currentActiveModule.featureModules[0]) || (this.currentActiveModule.hasChild && this.currentActiveModule.featureModules && this.currentActiveModule.featureModules.length && urlSegments[1]))) {
        this.currentActiveFeatureModule = this.currentActivatedRoute.routeConfig && this.currentActivatedRoute.routeConfig.data && this.currentActivatedRoute.routeConfig.data[environment.routeDataCustomPropertyNames.isThisModuleAFeature] && this.currentActiveModule.featureModules[0] || this.currentActiveModule.featureModules.find((featureModule: IFeatureModuleDTO) => featureModule.url === urlSegments[1]);

      }
    }
  }

  public getPrivilegesOfActiveComponentUrlRelativeToActiveFeature = (componentUrlRelativeTOActiveFeature: string = '/'): IPrivilegesDTO => this.currentActiveModule && this.currentActiveFeatureModule && this.currentActiveFeatureModule.components && this.currentActiveFeatureModule.components.find((component: IComponentDTO) => component.url === componentUrlRelativeTOActiveFeature) && this.currentActiveFeatureModule.components.find((component: IComponentDTO) => component.url === componentUrlRelativeTOActiveFeature).privilege;

  public navigateToSpecifiedDestination(destination: ActivatedRoute): void {
    this.router.navigate(['.'], { relativeTo: destination });
  }

  public logout(): void {
    this.keycloakService.logout(environment.redirectUriAfterLogout + (!!localStorage.getItem(environment.loggedInUserRoleNameLocalStorageKey) && `/role/${localStorage.getItem(environment.loggedInUserRoleNameLocalStorageKey)}` || ''));
  }
}
