import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconsProviderModule } from '../icons-provider.module';
import { NzTableModule, NzInputModule, NzButtonModule, NzNotificationModule, NzNotificationServiceModule, NzCardModule, NzGridModule, NzIconModule, NzPageHeaderModule, NzSelectModule, NzPaginationModule, NzListModule, NzToolTipModule, NzTabsModule, NzMenuModule, NzDropDownModule, NzProgressModule, NzAlertModule, NzModalModule, NzModalServiceModule, NzFormModule, NzRadioModule, NzLayoutModule, NzResultModule, NzDatePickerModule, NzDrawerModule, NzDrawerServiceModule, NzCheckboxModule, NzDividerModule, NzSwitchModule, NzDescriptionsModule, NzCollapseModule, NzUploadModule, NzPopconfirmModule, NzSpinModule, NzEmptyModule  } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextareaDialogComponent } from './textarea-dialog/textarea-dialog.component';
import { TableComponent, TableFilterDirective, TableMainActionsDirective, TableRowActionsDirective, TableLeadingColumnsDirective, TableTrailingColumnsDirective, TableLeadingRowsDirective, TableTrailingRowsDirective } from './table/table.component';
import { AdvancedFilterFieldComponent } from './advanced-filter-field/advanced-filter-field.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {NgxPaginationModule} from 'ngx-pagination'
const modules = [
  CommonModule,
  TranslateModule,
  IconsProviderModule,
  NzFormModule,
  FormsModule,
  ReactiveFormsModule,
  NzTableModule,
  NzInputModule,
  NzButtonModule,
  NzNotificationModule,
  NzNotificationServiceModule,
  NzCardModule,
  NzGridModule,
  NzIconModule,
  NzPageHeaderModule,
  NzMenuModule,
  NzDropDownModule,
  NzProgressModule,
  NzAlertModule,
  NzModalModule,
  NzModalServiceModule,
  NzSelectModule,
  NzPaginationModule,
  NzListModule,
  NzToolTipModule,
  NzTabsModule,
  NzRadioModule,
  NzLayoutModule,
  NzResultModule,
  NzDatePickerModule,
  NzDrawerModule,
  NzDrawerServiceModule,
  NzCheckboxModule,
  NzDividerModule,
  NzUploadModule,
  NzDescriptionsModule,
  NzCollapseModule,
  NzSwitchModule,
  NzUploadModule,
  NzPopconfirmModule,
  NzSpinModule,
  NzEmptyModule,
  NgMultiSelectDropDownModule
  ,NgxPaginationModule
];

@NgModule({
  declarations: [
    TextareaDialogComponent,
    TableComponent,
    TableFilterDirective,
    TableMainActionsDirective,
    TableRowActionsDirective,
    TableLeadingColumnsDirective,
    TableTrailingColumnsDirective,
    TableLeadingRowsDirective,
    TableTrailingRowsDirective,
    AdvancedFilterFieldComponent
  ],
  imports: modules,
  exports: [
    ...modules,
    TextareaDialogComponent,
    TableComponent,
    TableFilterDirective,
    TableMainActionsDirective,
    TableRowActionsDirective,
    TableLeadingColumnsDirective,
    TableTrailingColumnsDirective,
    TableLeadingRowsDirective,
    TableTrailingRowsDirective,
    AdvancedFilterFieldComponent
  ],
  entryComponents: [
    TextareaDialogComponent,
    TableComponent
  ]
})
export class SharedModule { }
