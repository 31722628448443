import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NzConfigService, NzModalService, NzI18nService, en_US } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { takeUntil, tap, filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { DataShareService } from './core/services/data-share.service';

@Component({
  selector: 'boa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private onComponentDestroy: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private title: Title,
    private nzConfigService: NzConfigService,
    private nzModalService: NzModalService,
    private dataShareService: DataShareService,
    private nzI18nService: NzI18nService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.setAntConfigurations();
    this.setTranslateConfigurations();
  }

  ngOnDestroy(): void {
    this.onComponentDestroy.next();
    this.onComponentDestroy.complete();
  }

  private setAntConfigurations(): void {
    this.nzConfigService.set('notification', { nzPlacement: 'bottomRight' });
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => {
        if (event.urlAfterRedirects.endsWith('/panel') && this.dataShareService.currentUserProfile) {
          this.router.navigateByUrl(`${environment.panelBaseUrlRelativeToRoot}/${this.dataShareService.currentUserProfile.isStaff && environment.staffPanelRouteUrlRelativeToPanel || environment.webUserPanelRouteUrlRelativeToPanel}`);
        }
        this.nzModalService.closeAll();
      }),
      takeUntil(this.onComponentDestroy)
    ).subscribe(); // Close all active modals on route change
  }

  private setTranslateConfigurations(): void {
    this.translateService.addLangs(environment.availableLanguages);
    this.translateService.onLangChange.pipe(takeUntil(this.onComponentDestroy)).subscribe((langChangeEvent: LangChangeEvent) => {
      this.title.setTitle(this.translateService.instant('bankOfAlbania'));
      localStorage.setItem(environment.selectedLanguageLocalStorageKey, langChangeEvent.lang);
      this.nzI18nService.setLocale(this.translateService.currentLang === 'al' && this.dataShareService.getNzI18nCustomLocale() || en_US);
    });
    this.translateService.use(localStorage.getItem(environment.selectedLanguageLocalStorageKey) || environment.defaultLanguage);
  }
}
